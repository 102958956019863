import { Divider, Flex } from "antd";
import { Link } from "react-router-dom";

export default function MobileHeader() {
  return (
    <div className="">
      <div className="divSpread p-20 align-items">
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          <h3 className="noto-serif-1" style={{ cursor: "pointer" }}>
            priya nath
          </h3>
        </Link>

        <Flex gap={40}>
          <Link to="/about">About</Link>
          <Link to="/shop">Store</Link>
          <Link to="/contact">Contact</Link>
        </Flex>
      </div>
      <Divider style={{ color: "black", border: "0.1px solid lightgrey" }} />
    </div>
  );
}
