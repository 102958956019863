import { Avatar, Flex, Image, Table } from "antd";
import { useLayoutContext } from "../providers/layout.provider";

export default function AboutPage() {
  const { isMobile } = useLayoutContext();

  const source = [
    {
      label: "Analytics & Reporting",
      desc: "Looker, Arcadia, Paxata, Cognos 10 & 11, Power BI, Pentaho, Advanced Excel, VBA & Macro",
    },
    {
      label: "ETL",
      desc: "Hive-HUE, SQL developer, SQL Workbench, Toad, Pentaho Data Integration",
    },
    {
      label: "Database",
      desc: "Snowflake, Hive, Oracle 12 C, Green Plum",
    },
    {
      label: "Product Management",
      desc: "Scrum, Kanban",
    },
  ];

  const columns = [
    {
      dataIndex: "label",
      key: 1,
      title: "Tools & Tech",
    },
    {
      dataIndex: "desc",
      key: 1,
      title: "",
    },
  ];

  return (
    <div className="justify-center p-40" style={{ width: "100%" }}>
      <div
        className="column center-align "
        style={{ width: isMobile ? "90%" : "50%" }}
      >
        <Image
          src="/assets/priya.jpeg"
          width={100}
          style={{ borderRadius: "50%" }}
        />

        <Flex gap={0} className="column">
          <h3>About Me</h3>

          <p style={{ justifyContent: "flex-start", textAlign: "left" }}>
            I’m a founding engineer and BI technical lead with a strong
            background in enterprise reporting and analytics, focused on
            creating BI solutions and strategies. I love solving business
            challenges involved in data provisioning, business integrity to
            provide valuable aspects to businesses achieving their goals.
          </p>
          <p>
            Technologies evolve quickly, so continuous learning is a key
            essential. I actively seek knowledge from cutting edge technologies
            and apply them towards business problems.
          </p>
          <p>
            Beyond my professional interests, I’m a mom, a wife, a dancer, ping
            pong player, gardener, and an excellent cook.
          </p>

          <div className="my-40">
            <Table
              bordered
              columns={columns}
              dataSource={source}
              pagination={{ hideOnSinglePage: true }}
            />
          </div>
        </Flex>
      </div>
    </div>
  );
}
