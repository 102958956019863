import dayjs from "dayjs";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import MobileHeader from "./components/mobile.header.component";
import LandingPage from "./pages/landing.page";
import ShoppingPage from "./pages/shopping.page";
import ContactPage from "./pages/contact.page";
import AboutPage from "./pages/about.page";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/*
        Do not move auth provider from here.
        The Mobile header component needs this.
        */}
        <MobileHeader />
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/shop" element={<ShoppingPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<LandingPage />} />

          <Route path="/" element={<LandingPage />} />
        </Routes>
        <FooterComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;

function FooterComponent() {
  return (
    <div className="footer p-16">
      <div className="footer-copyright">
        {" "}
        &copy; {dayjs().format("YYYY")} www.priyanath.us
      </div>
    </div>
  );
}
