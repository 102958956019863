import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Flex, Image } from "antd";

export default function ContactPage() {
  return (
    <div className="justify-center" style={{ height: 800 }}>
      <Flex gap={20} className="column">
        <h3>Priya Nath</h3>
        <div>
          <PhoneOutlined /> +1 (612) 323-2915
        </div>
        <div>
          <MailOutlined /> priya.nath1988@gmail.com
        </div>
        <div>
          <a
            style={{
              color: "var(--exhut-total-green)",
              textDecoration: "underline",
            }}
            href="mailto:priya.nath1988@gmail.com"
          >
            Email
          </a>
        </div>
      </Flex>
    </div>
  );
}
