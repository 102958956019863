import {
  Alert,
  Button,
  Card,
  Checkbox,
  Flex,
  Input,
  Modal,
  Row,
  Skeleton,
  Spin,
} from "antd";
import Meta from "antd/es/card/Meta";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { IBlock, useGetImages } from "../api/api.get";
import {
  usePostInquire,
  usePostInquiryCode,
  useVerifyInquiryCode,
} from "../api/api.post";
import { useNotifications } from "../hooks/notification";

export default function ShoppingPage() {
  const { data, isLoading } = useGetImages();
  const [item, setItem] = useState<IBlock>();
  const { notifySuccess, notification } = useNotifications({
    title: "Thank you for your message. We will get back to you asap.",
  });

  return (
    <div className="p-20">
      {notification}
      <ItemModal
        open={!!item}
        block={item}
        onConfirm={() => {
          setItem(undefined);
          notifySuccess();
        }}
        onCancel={() => setItem(undefined)}
      />
      <div className="p-0">
        <div
          className="center-align p-20 column"
          style={{ backgroundColor: "aliceblue", borderRadius: 20 }}
        >
          <h2>Hi there! &#128075;</h2>
          <h2>Welcome to my store</h2>
          <span>
            We are currently offering local pick and accepting cash only.
          </span>
        </div>
      </div>
      <div className="p-20 divRight infoMsg">Tap on an item to buy.</div>

      {isLoading && (
        <div className="center-align" style={{ height: 300 }}>
          <Spin tip="Loading" size="large"></Spin>
        </div>
      )}

      {!isLoading && (
        <Row gutter={[20, 20]}>
          {data?.bodyContent.blocks
            .filter((block: IBlock) => block.type === "image")
            .map((contentBlock: IBlock) => {
              const amountMatches = contentBlock.data.caption.match(/\$[0-9]+/);
              let amount =
                amountMatches && amountMatches.length > 0
                  ? amountMatches[0]
                  : "";
              const description = contentBlock.data.caption.replace(amount, "");
              return (
                <div className="mx-20 my-20">
                  <Card
                    hoverable
                    style={{ width: 240 }}
                    onClick={() => setItem(contentBlock)}
                    cover={<img alt="example" src={contentBlock.data.url} />}
                  >
                    <Meta title={amount} description={description} />
                  </Card>
                </div>
              );
            })}
        </Row>
      )}
    </div>
  );
}

interface IProps {
  open: boolean;
  block?: IBlock;
  onConfirm: () => void;
  onCancel: () => void;
}

function ItemModal({ open, onConfirm, onCancel, block }: IProps) {
  const {
    mutateAsync: postInquireCodeAsync,
    isPending: isPostInquireCodePending,
  } = usePostInquiryCode();
  const {
    mutateAsync: verifyInquiryCodeAsync,
    isPending: isVerificationPending,
    error: verifyCodeError,
  } = useVerifyInquiryCode();
  const { mutateAsync: postInquireAsync, isPending: isPostInquirePending } =
    usePostInquire();

  const handleVerifyPhoneNumber = () => {
    postInquireCodeAsync({ phone: formik.values.phone })
      .then((resp) => {
        formik.setFieldValue("codeSent", true);
      })
      .catch((e) => {
        formik.setFieldValue("codeSent", false);
      });
  };

  useEffect(() => {}, []);

  const handleVerifyInquiryCode = () => {
    formik.setFieldValue("phoneVerified", false);
    verifyInquiryCodeAsync({
      phone: formik.values.phone,
      code: +formik.values.code,
    })
      .then((resp) => {
        formik.setFieldValue("phoneVerified", true);
      })
      .catch((e) => {
        formik.setFieldValue("phoneVerified", false);
      });
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      message: `Hi Priya! I'm interested in the item ${block?.data.caption}. Could you confirm availability?`,
      phoneVerified: false,
      codeSent: false,
      code: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      phone: Yup.string().required("Please enter phone number."),
      phoneVerified: Yup.boolean(),
      codeSent: Yup.boolean(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      postInquireAsync({
        code: +formik.values.code,
        message: formik.values.message,
        phone: formik.values.phone,
        orgId: 2,
      })
        .then(() => {
          formik.resetForm();
          onConfirm();
        })
        .catch(() => {});
    },
  });

  const [acceptTerms, setAcceptTerms] = useState(false);

  return (
    <Modal
      title={block?.data.caption}
      open={open}
      onOk={formik.submitForm}
      onCancel={onCancel}
      okText={"Send"}
      cancelText="Close"
      okButtonProps={{ disabled: !formik.values.phoneVerified || !acceptTerms }}
    >
      <div className="p-20">
        <img src={block?.data.url} width={300} />

        <Flex gap={30} vertical className="mt-40">
          <Input.TextArea size="middle" {...formik.getFieldProps("message")} />

          <Flex gap={0} vertical>
            <Input
              size="middle"
              prefix={"+1"}
              placeholder="Enter your phone number..."
              {...formik.getFieldProps("phone")}
            />
            <ErrorPanel message={formik.errors.phone} />
          </Flex>

          {!formik.values.phoneVerified && !formik.values.codeSent && (
            <Button
              type="link"
              disabled={formik.values.phoneVerified}
              loading={isPostInquireCodePending}
              onClick={handleVerifyPhoneNumber}
            >
              Send One Time Passcode
            </Button>
          )}

          {formik.values.codeSent && (
            <Flex gap={0} vertical>
              <Input
                size="middle"
                placeholder="Enter code..."
                {...formik.getFieldProps("code")}
              />
              <ErrorPanel message={formik.errors.code} />

              <Button
                type="link"
                disabled={formik.values.phoneVerified}
                loading={isVerificationPending}
                onClick={handleVerifyInquiryCode}
              >
                Verify
              </Button>
            </Flex>
          )}

          {verifyCodeError && (
            <Alert type="error" message="Code is invalid or expired." />
          )}

          {formik.values.phoneVerified && (
            <Flex gap={10} vertical>
              <Alert type="success" message="Phone number verified." />

              <Checkbox onChange={(e) => setAcceptTerms(!acceptTerms)}>
                By checking this box, I confirm I am not impersonating another
                individual and using this product for communication purposes.
              </Checkbox>
            </Flex>
          )}
        </Flex>
      </div>
    </Modal>
  );
}

export function ErrorPanel({ message }: { message?: string }) {
  return (
    <div style={{ minHeight: 0, marginTop: 0 }}>
      {message && (
        <span className="errorMsg" style={{ fontSize: "small" }}>
          {message}
        </span>
      )}
    </div>
  );
}
