import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface IData {
  caption: string;
  url: string;
  withBorder: boolean;
}

export interface IBlock {
  id: string;
  type: "image" | "paragraph";
  data: IData;
}

interface IBodyContent {
  blocks: IBlock[];
}
export interface IPublishedContent {
  bodyContent: IBodyContent;
}

export function useGetImages() {
  return _useGet<IPublishedContent>(["organizations"], true);
}

function _useGet<T1>(keys: (string | undefined | null)[], enabled?: boolean) {
  let headers: any = {};

  const url = `https://www.blogsdb.expensehut.com/articles/671eaf603626f5b26552b729/published2`;

  return useQuery<T1, Error>({
    queryFn: async () => {
      return axios
        .get(url, {
          headers,
        })
        .then((resp) => resp.data);
    },
    queryKey: keys,
    enabled,
    retry: 0,
  });
}
