import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

export interface InquirePayload {
  phone: string;
  message: string;
  code: number;
  orgId: number;
}

export function usePostInquire() {
  return _usePost<any, any, InquirePayload>({
    path: `inquire`,
    keys: ["inquire"],
    resetKeys: [],
  });
}

export interface VerifyCodePayload {
  phone: string;
  code: number;
}

export function useVerifyInquiryCode() {
  return _usePost<any, any, VerifyCodePayload>({
    path: `inquire-code/validate`,
    keys: ["inquire-code"],
    resetKeys: [],
  });
}

export interface InquireCodePayload {
  phone: string;
}

export function usePostInquiryCode() {
  return _usePost<any, any, InquireCodePayload>({
    path: `inquire-code`,
    keys: ["inquire-code"],
    resetKeys: [],
  });
}

function _usePost<T1, T2, T3>({
  path,
  keys,
  resetKeys,
}: {
  path: string;
  keys?: string[];
  resetKeys?: string[];
}) {
  const _url = `${process.env.REACT_APP_BUSINESS_BE_URL}/${path}`;

  let headers: any = {
    "Content-Type": "application/json",
  };

  const queryClient = useQueryClient();
  return useMutation<T1, T2, T3>({
    mutationFn: async (req) => {
      return axios
        .post(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: resetKeys }),
  });
}
