import { Flex, Image } from "antd";
import { useLayoutContext } from "../providers/layout.provider";

export default function LandingPage() {
  const { isMobile } = useLayoutContext();

  return (
    <div className="justify-center" style={{ height: 800 }}>
      <Flex gap={50} className="column">
        <h1>Make an impact. Stand Out. Let's Grow</h1>

        <div style={{ position: "relative" }}>
          {!isMobile && (
            <Image
              width={400}
              wrapperStyle={{ left: 50, zIndex: -1 }}
              preview={false}
              style={{
                height: 500,
                borderRadius: 20,
                transform: "rotate(-10deg)",
                top: 40,
              }}
              src={"/assets/abstract.jpg"}
            />
          )}

          <Image
            width={400}
            preview={false}
            style={{ height: 500, borderRadius: 20 }}
            src={"/assets/priya-profile.png"}
          />
          {!isMobile && (
            <Image
              width={400}
              wrapperStyle={{ left: -50, zIndex: -1 }}
              preview={false}
              style={{
                height: 500,
                borderRadius: 20,
                transform: "rotate(10deg)",
                top: 40,
              }}
              src={"/assets/plant.jpg"}
            />
          )}
        </div>
      </Flex>
    </div>
  );
}
